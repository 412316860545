/* eslint-disable react/prop-types */
import React from 'react';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    render() {
        const { children } = this.props;
        const { hasError, error } = this.state;

        if (hasError) {
            return (
                <React.Fragment>
                    <p className='text-size-18 text-thin'>Something went wrong!</p>
                    <pre className='text-size-12'>{error.message}</pre>
                    <pre className='text-size-12'>{error.stack}</pre>
                </React.Fragment>
            );
        }

        return children;
    }
}
