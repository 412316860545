import React, { type ReactNode } from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import { colors } from '../../utils/colors';
import { isDesktop } from '../../../../src';

export const PLAYGROUNG_BG_WHITE = 'bg-white';
export const PLAYGROUNG_BG_LIGHTEST = 'bg-lightest';
export const PLAYGROUNG_BG_LIGHTER = 'bg-lighter';
export const PLAYGROUNG_BG_LIGHT = 'bg-light';

const getColor = () => [...colors.playground.map(color => color.background)];
const getBgToggleClasses = (color: string) => classNames('rounded border width-15 height-15 cursor-pointer', color);
const getSize = () => ['rioglyph-phone', 'rioglyph-width', 'rioglyph-desktop'];
const getPlaygroundSizeIcon = (icon: string) => classNames('rioglyph', icon);

type PlaygroundHeaderProps = {
    availableColors: ReactNode;
    onBackgroundChange: (newColor: string) => void;
    onPlaygroundSizeChange: (x: string) => void;
    onDetachWindow: () => void; // Add this new prop for handling window detach
    showSizeButtons: boolean;
    showBackgroundButtons: boolean;
};

export const PlaygroundHeader = (props: PlaygroundHeaderProps) => {
    const {
        availableColors,
        onBackgroundChange = noop,
        onPlaygroundSizeChange,
        onDetachWindow, // Destructure the new prop here
        showSizeButtons = true,
        showBackgroundButtons = true,
    } = props;

    return (
        <React.Fragment>
            {(showBackgroundButtons || showSizeButtons || availableColors) && (
                <div className='playground-header rounded-top'>
                    {availableColors ? (
                        <div className='display-flex flex-column'>
                            {availableColors}
                            <span className='playground-title'>Available Variations</span>
                        </div>
                    ) : (
                        <span className='seperator' />
                    )}
                    <div className='display-flex gap-20'>
                        <div className='display-flex flex-column align-items-center'>
                            <span
                                onClick={onDetachWindow}
                                className='cursor-pointer text-color-light hover-text-color-darkest rioglyph rioglyph-new-window'
                            />
                            <span className='playground-title'>Figma</span>
                        </div>
                        {showSizeButtons && isDesktop() && (
                            <div className='display-flex flex-column align-items-center'>
                                <div className='display-flex gap-5'>
                                    {getSize().map(icon => (
                                        <span
                                            className={`
                                        cursor-pointer text-color-light hover-text-color-darkest
                                        ${getPlaygroundSizeIcon(icon)}
                                    `}
                                            key={icon}
                                            onClick={() => {
                                                onPlaygroundSizeChange(icon.replace('rioglyph-', ''));
                                            }}
                                        />
                                    ))}
                                </div>
                                <span className='playground-title'>Size</span>
                            </div>
                        )}
                        {showBackgroundButtons && (
                            <div className='display-flex flex-column align-items-end'>
                                <div className='display-flex column-gap-5 justify-content-end'>
                                    {getColor().map(color => (
                                        <span
                                            className={`${getBgToggleClasses(color)} hover-scale-105`}
                                            key={color}
                                            onClick={() => onBackgroundChange(color)}
                                        />
                                    ))}
                                </div>
                                <span className='playground-title'>Background</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
