// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren, type ReactNode } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';
import type { Options } from '@popperjs/core';

import Button from '../button/Button';
import OverlayTrigger from '../overlay/OverlayTrigger';
import Tooltip, { type TooltipWidth } from '../tooltip/Tooltip';
import { TEXT_ALIGNMENT, type TextAlignment } from '../../values/TextAlignment';
import { PLACEMENT, type Placement } from '../../values/Placement';

export type OnboardingButton = {
    text: string | React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    iconName?: string;
};

type PopperConfig = Partial<Options>;

export type OnboardingTipProps = {
    /**
     * The ID of the DOM element.
     */
    id?: string;

    /**
     * Indicates whether the onboarding tip is shown.
     *
     * @default false
     */
    show: boolean;

    /**
     * Defines whether to show a close icon.
     *
     * @default true
     */
    showCloseIcon?: boolean;

    /**
     * Define how the text should be aligned.
     *
     * Possible values are: `left`, `center` or `right`.
     *
     * @default 'left'
     */
    textAlignment?: TextAlignment;

    /**
     * The tooltip's width.
     *
     * Possible values are:
     * - `auto`
     * - 100
     * - 150
     * - 200
     * - 250
     * - 300
     * - 350
     * - 400
     * - 450
     * - 500
     */
    width?: TooltipWidth;

    /**
     * Callback function for when the component shall be hidden.
     *
     * @default noop
     */
    onHide: VoidFunction;

    /**
     * Defines whether to have a clickflow journey.
     *
     * @default false
     */
    clickflow?: boolean;

    /**
     * Adds a button to navigate to the previous onboarding tip when using the click flow onboarding.
     */
    previousButton?: OnboardingButton;

    /**
     * Adds a button to navigate to the next onboarding tip when using the click flow onboarding.
     */
    nextButton?: OnboardingButton;

    /**
     * Changes the z-index.
     *
     * @default false
     */
    useInDialog?: boolean;

    /**
     * Additional classes to be set on the wrapper element.
     */
    className?: string;

    /**
     * The title of the onboarding tip.
     */
    title?: string | ReactNode;

    /**
     * The content of the onboarding tip.
     */
    content?: string | ReactNode;

    /**
     * Prevents onboarding tips from being cut off horizontally at screen borders.
     *
     * @default true
     */
    preventOverflow?: boolean;

    /**
     * A Popper.js config object passed to the underlying popper instance.
     */
    popperConfig?: PopperConfig;

    /**
     * Define how the component should be placed.
     *
     * Possible values are:
     * - `auto-start`
     * - `auto`
     * - `auto-end`
     * - `top-start`
     * - `top`
     * - `top-end`
     * - `right-start`
     * - `right`
     * - `right-end`
     * - `bottom-start`
     * - `bottom`
     * - `bottom-end`
     * - `left-start`
     * - `left`
     * - `left-end`
     *
     * @default 'bottom'
     */
    placement?: Placement;
};

const OnboardingTip = (props: PropsWithChildren<OnboardingTipProps>) => {
    const {
        id,
        show = false,
        placement = PLACEMENT.BOTTOM,
        title,
        content,
        onHide = noop,
        children,
        textAlignment = TEXT_ALIGNMENT.LEFT,
        useInDialog = false,
        showCloseIcon = true,
        clickflow = false,
        previousButton,
        nextButton,
        className = '',
        width,
        preventOverflow = true,
        popperConfig,
    } = props;

    const clickFlowWithButtons = (
        <div className='display-flex flex-column gap-25'>
            <div>{content}</div>
            <div className={`btn-toolbar justify-content-between ${showCloseIcon ? 'margin-right--25' : ''}`}>
                {previousButton ? (
                    <div className='position-relative'>
                        <div className='bg-black opacity-10 rounded display-absolute inset-0' />
                        <Button
                            bsStyle={Button.SECONDARY}
                            variant={Button.VARIANT_OUTLINE}
                            className='border-none text-color-white'
                            onClick={previousButton.onClick}
                        >
                            {previousButton.iconName && (
                                <span className={`rioglyph ${previousButton.iconName} text-color-white`} />
                            )}
                            <span>{previousButton.text}</span>
                        </Button>
                    </div>
                ) : (
                    <div />
                )}
                {nextButton ? (
                    <Button
                        bsStyle={Button.SECONDARY}
                        variant={Button.VARIANT_OUTLINE}
                        className='border-color-white text-color-white'
                        onClick={nextButton.onClick}
                        iconRight
                    >
                        <span>{nextButton.text}</span>
                        {nextButton.iconName && <span className={`rioglyph ${nextButton.iconName} text-color-white`} />}
                    </Button>
                ) : (
                    <div />
                )}
            </div>
        </div>
    );

    const tooltipWrapperClasses = classNames(
        useInDialog && 'z-index-max',
        className && className,
        clickflow && 'onboarding-clickflow'
    );

    const overlay = (
        <Tooltip
            className={tooltipWrapperClasses}
            tooltipStyle={Tooltip.STYLE_ONBOARDING}
            id={id}
            onClick={onHide}
            width={width}
            textAlignment={textAlignment}
            allowOnTouch
        >
            <div className='display-flex'>
                <div className='display-flex flex-column flex-1-1'>
                    {title && <div className='tooltip-title'>{title}</div>}
                    {content && <div className='tooltip-content'>{clickflow ? clickFlowWithButtons : content}</div>}
                </div>
                {showCloseIcon && <span className='tooltip-close rioglyph rioglyph-remove' />}
            </div>
        </Tooltip>
    );

    const actualPopperConfig: PopperConfig = popperConfig || {
        strategy: 'fixed',
        modifiers: [
            {
                name: 'preventOverflow',
                // Popper.js default is false but we enable it by default
                options: { altAxis: preventOverflow },
            },
            // Use an arrow modifier, the arrow ref will be injected in OverlayTrigger with the right props
            { name: 'arrow', options: {} },
        ],
    };

    const trigger = (
        <OverlayTrigger
            placement={placement}
            trigger='click'
            overlay={overlay}
            onToggle={onHide}
            show={show}
            rootClose={false}
            popperConfig={actualPopperConfig}
        >
            {children}
        </OverlayTrigger>
    );

    return show ? trigger : <>{children}</>;
};

OnboardingTip.AUTO_START = PLACEMENT.AUTO_START;
OnboardingTip.AUTO = PLACEMENT.AUTO;
OnboardingTip.AUTO_END = PLACEMENT.AUTO_END;
OnboardingTip.TOP_START = PLACEMENT.TOP_START;
OnboardingTip.TOP = PLACEMENT.TOP;
OnboardingTip.TOP_END = PLACEMENT.TOP_END;
OnboardingTip.BOTTOM_START = PLACEMENT.BOTTOM_START;
OnboardingTip.BOTTOM = PLACEMENT.BOTTOM;
OnboardingTip.BOTTOM_END = PLACEMENT.BOTTOM_END;
OnboardingTip.RIGHT_START = PLACEMENT.RIGHT_START;
OnboardingTip.RIGHT = PLACEMENT.RIGHT;
OnboardingTip.RIGHT_END = PLACEMENT.RIGHT_END;
OnboardingTip.LEFT_START = PLACEMENT.LEFT_START;
OnboardingTip.LEFT = PLACEMENT.LEFT;
OnboardingTip.LEFT_END = PLACEMENT.LEFT_END;

OnboardingTip.TEXT_ALIGNMENT_LEFT = TEXT_ALIGNMENT.LEFT;
OnboardingTip.TEXT_ALIGNMENT_CENTER = TEXT_ALIGNMENT.CENTER;
OnboardingTip.TEXT_ALIGNMENT_RIGHT = TEXT_ALIGNMENT.RIGHT;

export default OnboardingTip;
