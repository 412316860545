import React from 'react';

// When using labels:
//  - label-default: Beta
//  - label-info: Updates
//  - label-success: New
//  - label-warning: Changes
//  - label-danger: Breaking Changes
export const Label = ({ type, children }) => (
    <span className={`label label-${type} label-filled label-condensed`}>{children}</span>
);

export const NavLinkItem = ({ type, label, children }) => (
    <div className='display-flex justify-content-between'>
        {children}
        <div className='margin-top--2'>
            <Label type={type}>{label}</Label>
        </div>
    </div>
);

export const Beta = ({ type = 'default', label = 'Beta', children }) => (
    <NavLinkItem type={type} label={label}>
        {children}
    </NavLinkItem>
);

export const Update = ({ type = 'info', label = 'Update', children }) => (
    <NavLinkItem type={type} label={label}>
        {children}
    </NavLinkItem>
);

export const New = ({ type = 'success', label = 'New', children }) => (
    <NavLinkItem type={type} label={label}>
        {children}
    </NavLinkItem>
);

export const Fixed = ({ type = 'warning', label = 'Fixed', children }) => (
    <NavLinkItem type={type} label={label}>
        {children}
    </NavLinkItem>
);

export const Breaking = ({ type = 'danger', label = 'Breaking', children }) => (
    <NavLinkItem type={type} label={label}>
        {children}
    </NavLinkItem>
);
