import { Component } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import { ExpanderList } from '../index';
import { CodeBlock } from './CodeBlock';

export class CodeSnippets extends Component {
    getCodeBlock(codeBlock, language, ref) {
        return (
            <CodeBlock language={language} exampleRef={ref}>
                {codeBlock}
            </CodeBlock>
        );
    }

    render() {
        // Workaround: we need to define dummy content in order to have the ExpandableList item expandable.
        // The content will be replaced later with the extracted DOM markup
        const {
            showHtml,
            codeHtml,
            codeHtmlTitle,
            codeReact,
            codeReactTitle,
            codeJS,
            codeJSTitle,
            exampleRef,
            componentProps,
            classes,
            openBlock,
        } = this.props;

        // Only pass the ref down when no html code example is provided
        const ref = isEmpty(codeHtml) ? exampleRef : undefined;

        const listItems = [];

        if (codeReact) {
            listItems.push({
                header: <span className='code-sample-header'>{codeReactTitle}</span>,
                headerClassName: 'expander-list-header-bg',
                body: this.getCodeBlock(codeReact, 'language-jsx'),
                className: 'overflow-hidden',
                open: openBlock === 'codeReact',
            });
        }

        if (showHtml) {
            listItems.push({
                header: <span className='code-sample-header'>{codeHtmlTitle}</span>,
                headerClassName: 'expander-list-header-bg',
                body: this.getCodeBlock(codeHtml, 'language-markup', ref),
                className: 'overflow-hidden',
                open: openBlock === 'codeHtml',
            });
        }

        if (codeJS) {
            listItems.push({
                header: <span className='code-sample-header'>{codeJSTitle}</span>,
                headerClassName: 'expander-list-header-bg',
                body: this.getCodeBlock(codeJS, 'language-javascript'),
                className: 'overflow-hidden',
                open: openBlock === 'codeJS',
            });
        }

        if (componentProps) {
            listItems.push({
                header: <span className='code-sample-header'>Props</span>,
                headerClassName: 'expander-list-header-bg',
                body: (
                    <div>
                        {componentProps}
                        <div className='margin-top-15'>
                            <i>* Required props are displayed in bold</i>
                        </div>
                    </div>
                ),
                className: 'overflow-hidden',
                open: openBlock === 'props',
            });
        }

        if (classes) {
            listItems.push({
                header: <span className='code-sample-header'>Classes</span>,
                headerClassName: 'expander-list-header-bg',
                body: <div>{classes}</div>,
                className: 'overflow-hidden',
                open: openBlock === 'classes',
            });
        }

        return (
            <div className='playground-expander'>
                <ExpanderList
                    className='border border-top-only rounded-bottom'
                    border={false}
                    rounded={false}
                    items={listItems}
                />
            </div>
        );
    }
}
