/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import React from 'react';

import { NavLink } from 'react-router-dom';

import { UikitMenuSidebar } from '../../components/UikitMenuSidebar';
import { UikitMenu } from '../../components/UikitMenu';
import { routes } from '../../routes';

const BASE_URL = routes.COMPONENTS;

export const getComponentsMenuItems = () => [
    {
        group: 'Application',
        navItems: [
            {
                key: 'ApplicationLayout',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/appLayout`}>
                        ApplicationLayout
                    </NavLink>
                ),
                meta: 'appLayout',
            },
            {
                key: 'ApplicationHeader',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/appHeader`}>
                        ApplicationHeader
                    </NavLink>
                ),
                meta: 'appHeader actionBarItems serviceInfo about title applicationHeader',
            },
            {
                key: 'BottomSheet',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/bottomSheet`}>
                        BottomSheet
                    </NavLink>
                ),
                meta: 'bottomSheet timed',
            },
            {
                key: 'Sidebar',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/sidebar`}>
                        Sidebar
                    </NavLink>
                ),
                meta: 'sidebar',
            },
        ],
    },
    {
        group: 'Navigation',
        navItems: [
            {
                key: 'MainNavigation',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/mainNavigation`}>
                        MainNavigation
                    </NavLink>
                ),
                meta: 'main navigation',
            },
            {
                key: 'SubNavigation',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/subNavigation`}>
                        SubNavigation
                    </NavLink>
                ),
                meta: 'subNavigation navigation',
            },
            {
                key: 'AppNavigationBar',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/appNavigationBar`}>
                        AppNavigationBar
                    </NavLink>
                ),
                meta: 'appNavigationBar navigation',
            },
            {
                key: 'PagerPage',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/pager`}>
                        Pager
                    </NavLink>
                ),
                meta: 'pager button',
            },
        ],
    },
    {
        group: 'Interaction',
        navItems: [
            {
                key: 'Button',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/button`}>
                        Button
                    </NavLink>
                ),
                meta: 'button',
            },
            {
                key: 'Checkbox',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/checkbox`}>
                        Checkbox
                    </NavLink>
                ),
                meta: 'checkbox',
            },
            {
                key: 'ClearableInput',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/clearableInput`}>
                        ClearableInput
                    </NavLink>
                ),
                meta: 'clearableInput',
            },
            {
                key: 'EditableContent',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/editableContent`}>
                        EditableContent
                    </NavLink>
                ),
                meta: 'editableContent text inline edit',
            },
            {
                key: 'LoadMoreButton',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/loadMore`}>
                        LoadMoreButton
                    </NavLink>
                ),
                meta: 'loadMore',
            },
            {
                key: 'NumberControl',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/numbercontrol`}>
                        NumberControl
                    </NavLink>
                ),
                meta: 'numbercontrol',
            },
            {
                key: 'RadioButton',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/radiobutton`}>
                        RadioButton
                    </NavLink>
                ),
                meta: 'radiobutton',
            },
            {
                key: 'SaveableInput',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/saveableInput`}>
                        SaveableInput
                    </NavLink>
                ),
                meta: 'save input edit form saveableInput',
            },
            {
                key: 'Slider',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/sliders`}>
                        Slider
                    </NavLink>
                ),
                meta: 'sliders range',
            },
            {
                key: 'Switch',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/switch`}>
                        Switch
                    </NavLink>
                ),
                meta: 'switch',
            },
            {
                key: 'ToggleButton',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/toggleButton`}>
                        ToggleButton
                    </NavLink>
                ),
                meta: 'toggleButton',
            },
        ],
    },
    {
        group: 'Show/Hide',
        navItems: [
            {
                key: 'Collapse',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/collapse`}>
                        Collapse
                    </NavLink>
                ),
                meta: 'collapse',
            },
            {
                key: 'Expanders',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/expander`}>
                        Expanders
                    </NavLink>
                ),
                meta: 'expander',
            },
            {
                key: 'Fade',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/fade`}>
                        Fade
                    </NavLink>
                ),
                meta: 'fade transition animate',
            },
            {
                key: 'FadeExpander',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/fadeExpander`}>
                        FadeExpander
                    </NavLink>
                ),
                meta: 'fadeExpander transition animate expand collapse',
            },
            {
                key: 'FadeUp',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/fadeUp`}>
                        FadeUp
                    </NavLink>
                ),
                meta: 'fadeUp transition animate',
            },
        ],
    },
    {
        group: 'Selection',
        navItems: [
            {
                key: 'AssetTree',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/assetTree`}>
                        <span>AssetTree</span>
                    </NavLink>
                ),
                meta: 'assetTree',
            },
            {
                key: 'AutoSuggest',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/autosuggests`}>
                        AutoSuggest
                    </NavLink>
                ),
                meta: 'autosuggests',
            },
            {
                key: 'Dropdowns',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/dropdowns`}>
                        Button Dropdowns
                    </NavLink>
                ),
                meta: 'button dropdowns',
            },
            {
                key: 'Selects',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/selects`}>
                        Selects
                    </NavLink>
                ),
                meta: 'selects multiselect',
            },
        ],
    },
    {
        group: 'Pickers',
        navItems: [
            {
                key: 'DatePickers',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/datepickers`}>
                        DatePicker
                    </NavLink>
                ),
                meta: 'datepickers',
            },
            {
                key: 'FilePickers',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/filePickers`}>
                        FilePicker
                    </NavLink>
                ),
                meta: 'filePickers',
            },
            {
                key: 'TimePickers',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/timepicker`}>
                        TimePicker
                    </NavLink>
                ),
                meta: 'timepicker',
            },
        ],
    },
    {
        group: 'Progress',
        navItems: [
            {
                key: 'StatusBar',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/statusBar`}>
                        StatusBar
                    </NavLink>
                ),
                meta: 'statusBar',
            },
            {
                key: 'SteppedProgress',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/steppedProgressBars`}>
                        SteppedProgress
                    </NavLink>
                ),
                meta: 'steppedProgressBars',
            },
        ],
    },
    {
        group: 'Content',
        navItems: [
            {
                key: 'Animations',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/animations`}>
                        Animations
                    </NavLink>
                ),
                meta: 'animations',
            },
            {
                key: 'AnimatedNumber',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/animatedNumber`}>
                        AnimatedNumber
                    </NavLink>
                ),
                meta: 'animatedNumber',
            },
            {
                key: 'Carousel',
                item: <NavLink to={`${BASE_URL}/carousel`}>Carousel</NavLink>,
                meta: 'carousel',
            },
            {
                key: 'ContentLoader',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/contentLoader`}>
                        ContentLoader
                    </NavLink>
                ),
                meta: 'contentLoader',
            },
            {
                key: 'DataTabs',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/dataTabs`}>
                        DataTabs
                    </NavLink>
                ),
                meta: 'dataTabs',
            },
            {
                key: 'Dialogs',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/dialogs`}>
                        Dialogs
                    </NavLink>
                ),
                meta: 'dialogs split save confirmation info simple media release releasenotes',
            },
            {
                key: 'GroupedItemList',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/groupedItemList`}>
                        GroupedItemList
                    </NavLink>
                ),
                meta: 'groupedItemList lists items',
            },
            {
                key: 'NoData',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/noData`}>
                        NoData
                    </NavLink>
                ),
                meta: 'noData',
            },
            {
                key: 'Page',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/page`}>
                        Page
                    </NavLink>
                ),
                meta: 'page document paper',
            },
            {
                key: 'ResponsiveColumnStripe',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/responsiveColumnStripe`}>
                        ResponsiveColumnStripe
                    </NavLink>
                ),
                meta: 'responsiveColumnStripe',
            },
            {
                key: 'States',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/states`}>
                        States
                    </NavLink>
                ),
                meta: 'states',
            },
            {
                key: 'StatsWidgets',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/statsWidgets`}>
                        StatsWidgets
                    </NavLink>
                ),
                meta: 'statsWidgets',
            },
            {
                key: 'Tables',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/tables`}>
                        Tables
                    </NavLink>
                ),
                meta: 'tables',
            },
            {
                key: 'Teaser',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/teaser`}>
                        Teaser
                    </NavLink>
                ),
                meta: 'teaser',
            },
        ],
    },
    {
        group: 'Misc',
        navItems: [
            {
                key: 'Activity',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/activity`}>
                        Activity
                    </NavLink>
                ),
                meta: 'activity',
            },
            {
                key: 'AspectRatioPlaceholder',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/aspectRatioPlaceholder`}>
                        AspectRatioPlaceholder
                    </NavLink>
                ),
                meta: 'aspect placeholder image',
            },
            {
                key: 'CalendarStripe',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/calendarStripe`}>
                        CalendarStripe
                    </NavLink>
                ),
                meta: 'calendarStripe',
            },
            {
                key: 'Divider',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/divider`}>
                        Divider
                    </NavLink>
                ),
                meta: 'divider',
            },
            {
                key: 'Feedback',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/feedback`}>
                        Feedback
                    </NavLink>
                ),
                meta: 'feedback rating thumbs-up thumbs-up smiley',
            },
            {
                key: 'ImagePreloader',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/imagePreloader`}>
                        ImagePreloader
                    </NavLink>
                ),
                meta: 'image preloader',
            },
            {
                key: 'ListMenu',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/listMenu`}>
                        ListMenu
                    </NavLink>
                ),
                meta: 'listMenu',
            },
            {
                key: 'LicensePlate',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/licensePlate`}>
                        LicensePlate
                    </NavLink>
                ),
                meta: 'listMenu',
            },
            {
                key: 'Notifications',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/notifications`}>
                        Notifications
                    </NavLink>
                ),
                meta: 'notifications',
            },
            {
                key: 'Onboarding',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/onboarding`}>
                        Onboarding
                    </NavLink>
                ),
                meta: 'onboarding',
            },
            {
                key: 'Popover',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/popover`}>
                        Popover
                    </NavLink>
                ),
                meta: 'popover',
            },
            {
                key: 'Position',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/position`}>
                        Position
                    </NavLink>
                ),
                meta: 'position',
            },
            {
                key: 'ReleaseNotes',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/releaseNotes`}>
                        ReleaseNotes
                    </NavLink>
                ),
                meta: 'releaseNotes',
            },
            {
                key: 'Resizer',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/resizer`}>
                        Resizer
                    </NavLink>
                ),
                meta: 'resizer',
            },
            {
                key: 'Responsive Video',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/responsiveVideo`}>
                        ResponsiveVideo
                    </NavLink>
                ),
                meta: 'responsiveVideo',
            },
            {
                key: 'Rules',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/rules`}>
                        Rules
                    </NavLink>
                ),
                meta: 'rule connector container rulecontainer ruleconnector',
            },
            {
                key: 'SmoothScrollbars',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/smoothScrollbars`}>
                        SmoothScrollbars
                    </NavLink>
                ),
                meta: 'smoothscrollbar',
            },
            {
                key: 'Spinner',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/spinners`}>
                        Spinner
                    </NavLink>
                ),
                meta: 'spinners loader',
            },
            {
                key: 'supportMarker',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/supportMarker`}>
                        SupportMarker
                    </NavLink>
                ),
                meta: 'supportMarker',
            },
            {
                key: 'TagManager',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/tagManager`}>
                        TagManager
                    </NavLink>
                ),
                meta: 'tagManager',
            },
            {
                key: 'Tags',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/tags`}>
                        Tags
                    </NavLink>
                ),
                meta: 'tags',
            },
            {
                key: 'Tooltip',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/tooltip`}>
                        Tooltip
                    </NavLink>
                ),
                meta: 'tooltip simple',
            },
        ],
    },
    {
        group: 'CSS Only',
        navItems: [
            {
                key: 'Chat',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/chat`}>
                        Chat
                    </NavLink>
                ),
                meta: 'chat css only',
            },
            {
                key: 'IconList',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/iconList`}>
                        IconList
                    </NavLink>
                ),
                meta: 'iconList css only',
            },
            {
                key: 'Timeline',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/timeline`}>
                        Timeline
                    </NavLink>
                ),
                meta: 'timeline css only',
            },
        ],
    },
    {
        group: 'Hooks',
        navItems: [
            {
                key: 'useAfterMount',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useAfterMount`}>
                        useAfterMount
                    </NavLink>
                ),
                meta: 'useAfterMount hook',
            },
            {
                key: 'useClickOutside',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useClickOutside`}>
                        useClickOutside
                    </NavLink>
                ),
                meta: 'useClickOutside hook',
            },
            {
                key: 'useClipboard',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useClipboard`}>
                        useClipboard
                    </NavLink>
                ),
                meta: 'useClipboard hook',
            },
            {
                key: 'useDarkMode',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useDarkMode`}>
                        useDarkMode
                    </NavLink>
                ),
                meta: 'useDarkMode hook',
            },
            {
                key: 'useDebugInfo',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useDebugInfo`}>
                        useDebugInfo
                    </NavLink>
                ),
                meta: 'useDebugInfo hook',
            },
            {
                key: 'useEffectOnce',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useEffectOnce`}>
                        useEffectOnce
                    </NavLink>
                ),
                meta: 'useEffectOnce hook',
            },
            {
                key: 'useElapsedTime',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useElapsedTime`}>
                        useElapsedTime
                    </NavLink>
                ),
                meta: 'useElapsedTime timeout hook',
            },
            {
                key: 'useElementSize',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useElementSize`}>
                        useElementSize
                    </NavLink>
                ),
                meta: 'useElementSize hook',
            },
            {
                key: 'useEsc',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useEsc`}>
                        useEsc
                    </NavLink>
                ),
                meta: 'useEsc hook',
            },
            {
                key: 'useEvent',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useEvent`}>
                        useEvent
                    </NavLink>
                ),
                meta: 'useEvent hook',
            },
            {
                key: 'useFocusTrap',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useFocusTrap`}>
                        useFocusTrap
                    </NavLink>
                ),
                meta: 'useFocusTrap hook',
            },
            {
                key: 'useFullscreen',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useFullscreen`}>
                        useFullscreen
                    </NavLink>
                ),
                meta: 'useFullscreen hook',
            },
            {
                key: 'useHover',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useHover`}>
                        useHover
                    </NavLink>
                ),
                meta: 'useHover hook',
            },
            {
                key: 'useInterval',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useInterval`}>
                        useInterval
                    </NavLink>
                ),
                meta: 'useInterval hook',
            },
            {
                key: 'useKey',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useKey`}>
                        useKey
                    </NavLink>
                ),
                meta: 'useKey hook',
            },
            {
                key: 'useLocalStorage',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useLocalStorage`}>
                        useLocalStorage / useSessionStorage
                    </NavLink>
                ),
                meta: 'useLocalStorage hook',
            },
            {
                key: 'useMutationObserver',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useMutationObserver`}>
                        useMutationObserver
                    </NavLink>
                ),
                meta: 'useMutationObserver hook',
            },
            {
                key: 'useOnlineStatus',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useOnlineStatus`}>
                        useOnlineStatus
                    </NavLink>
                ),
                meta: 'useOnlineStatus hook',
            },
            {
                key: 'useOnScreen',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useOnScreen`}>
                        useOnScreen
                    </NavLink>
                ),
                meta: 'useOnScreen hook',
            },
            {
                key: 'usePostMessage',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/usePostMessage`}>
                        usePostMessage
                    </NavLink>
                ),
                meta: 'usePostMessage hook iframe event widget',
            },
            {
                key: 'usePrevious',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/usePrevious`}>
                        usePrevious
                    </NavLink>
                ),
                meta: 'usePrevious hook',
            },
            {
                key: 'useResizeObserver',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useResizeObserver`}>
                        useResizeObserver
                    </NavLink>
                ),
                meta: 'useResizeObserver resize observer hook',
            },
            {
                key: 'useScrollPosition',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useScrollPosition`}>
                        useScrollPosition
                    </NavLink>
                ),
                meta: 'useScrollPosition hook scroll position offset',
            },
            {
                key: 'useSorting',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useSorting`}>
                        useSorting
                    </NavLink>
                ),
                meta: 'useSorting hook order sort ascending descending',
            },
            {
                key: 'useStateWithValidation',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useStateWithValidation`}>
                        useStateWithValidation
                    </NavLink>
                ),
                meta: 'useStateWithValidation hook',
            },
            {
                key: 'useTimeout',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useTimeout`}>
                        useTimeout
                    </NavLink>
                ),
                meta: 'useTimeout hook',
            },
            {
                key: 'useWindowResize',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/useWindowResize`}>
                        useWindowResize
                    </NavLink>
                ),
                meta: 'useWindowResize hook',
            },
        ],
    },
    {
        group: 'Helper',
        navItems: [
            {
                key: 'DeviceUtils',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/deviceUtils`}>
                        Device Utils
                    </NavLink>
                ),
                meta: 'deviceUtils',
            },
            {
                key: 'RouteUtils',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/routeUtils`}>
                        Route Utils
                    </NavLink>
                ),
                meta: 'routeUtils',
            },
            {
                key: 'URLFeatureToggles',
                item: (
                    <NavLink activeClassName='active' to={`${BASE_URL}/featureToggles`}>
                        URL feature toggles
                    </NavLink>
                ),
                meta: 'URLFeatureToggles URL feature toggles',
            },
        ],
    },
];

export const ComponentsMenu = () => (
    <UikitMenuSidebar title='Components'>
        <UikitMenu />
    </UikitMenuSidebar>
);
